import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/header/Header";

import ScrollToTop from "./utils/scrollToTop";

import Home from "./pages/Home";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import TermsOfUse from "./pages/TermsOfUse";
import LicenseAgreement from "./pages/LicenseAgreement";
import NotFound from "./pages/NotFound";

import "./css/main.css";
import "./assets/fonts/Gilroy/style.css";
import SmoothTransform from "./utils/smoothTransform/smoothTransform";

function App() {
    useEffect(() => {
        window.yaContextCb = window.yaContextCb || [];
        const script = document.createElement('script');
        script.src = "https://yandex.ru/ads/system/context.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                    "blockId": "R-A-12629619-1",
                    "renderTo": "yandex_rtb_R-A-12629619-1"
                });
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='App'>
            <Router>
                <Header />
                <div className="content-wrapper">
                    <div className="main-content">
                        <SmoothTransform>
                            <Routes>
                                <Route path='/' element={<Home />} />
                                <Route path='/contacts' element={<Contacts />} />
                                <Route path='/about' element={<About />} />
                                <Route path='/license_agreement' element={<LicenseAgreement />} />
                                <Route path='/terms_of_use' element={<TermsOfUse />} />
                                <Route path='/*' element={<NotFound />} />
                            </Routes>
                        </SmoothTransform>
                    </div>
                    <div className="ad-container" id="yandex_rtb_R-A-12629619-1"></div>
                </div>
            </Router>
        </div>
    );
}

export default App;
